import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31eb83e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-end mb-3" }
const _hoisted_2 = { class: "d-flex mb-3" }
const _hoisted_3 = { class: "search-wrapper me-2" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSVG = _resolveComponent("IconSVG")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_AccountModal = _resolveComponent("AccountModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "text-end mb-1",
        type: "primary",
        onClick: _ctx.addAccount
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_IconSVG, { name: "plus" })
        ]),
        default: _withCtx(() => [
          _createTextVNode("เพิ่มสิทธิ์การใช้งาน ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_card, {
      class: "w-100 h-auto",
      bodyStyle: { padding: '24px 15px' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_input_search, {
              placeholder: "ค้นหาชื่อบัญชี",
              class: "w-100",
              onSearch: _ctx.onSearch,
              "enter-button": undefined
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_IconSVG, { name: "search" })
              ]),
              _: 1
            }, 8, ["onSearch"])
          ])
        ]),
        _createVNode(_component_a_table, {
          columns: _ctx.columns,
          "row-key": (record) => record.id,
          "data-source": _ctx.dataSource,
          pagination: _ctx.pagination,
          loading: _ctx.loading,
          onChange: _ctx.handleTableChange
        }, {
          login: _withCtx(({ text }) => [
            _createVNode(_component_IconSVG, {
              name: text ? 'correct' : 'incorrect'
            }, null, 8, ["name"])
          ]),
          date: _withCtx(({ text }) => [
            _createTextVNode(_toDisplayString(_ctx.format(new Date(text), "dd/MM/yyyy")), 1)
          ]),
          action: _withCtx(({ text }) => [
            _createVNode(_component_a_tooltip, null, {
              title: _withCtx(() => [
                _createTextVNode("แก้ไขข้อมูลสิทธิใช้งาน")
              ]),
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: ($event: any) => (_ctx.handleClickEdit(text)),
                  class: "fw-light me-3"
                }, [
                  _createVNode(_component_EditOutlined)
                ], 8, _hoisted_4)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["columns", "row-key", "data-source", "pagination", "loading", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_AccountModal, {
      visible: _ctx.visibleModal,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visibleModal) = $event)),
      type: "bigData",
      accountId: _ctx.selectedAccountId,
      onCloseModal: _ctx.handleCloseModal
    }, null, 8, ["visible", "accountId", "onCloseModal"])
  ], 64))
}